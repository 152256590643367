import { Layout } from "@components";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteLocation } from "@interfaces";
import { t } from "@utils/Translations";
import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";
import { Row } from "reactstrap";

import {
    AdditionalSectionList,
    EducationList,
    Experience,
    Footer,
    LanguagesList,
    PersonCard,
    Skills,
} from "../containers";
import * as style from "./style.module.scss";

export const query = graphql`
    query($cvId: String!, $teamId: String, $locale: String!) {
        team: contentfulTeamPage(id: { eq: $teamId }) {
            id
            contentful_id
            internalLabel
            node_locale
            theming {
                ...Theming
            }
            contactPerson: contactPerson {
                ...ContactPerson
            }
            teamProfiles {
                id
            }
            languages
        }
        cv: contentfulCv(
            contentful_id: { eq: $cvId }
            node_locale: { eq: $locale }
        ) {
            person {
                ...Person
                id
                region
                mainOffice {
                    ...Location
                }
                about {
                    raw
                }
                driversLicense
                dateOfBirth
                portrait {
                    gatsbyImageData(width: 480, placeholder: BLURRED)
                    file {
                        url
                    }
                }
                languages {
                    id
                    language
                    level
                    reading
                    speaking
                    stars
                }
            }
            education {
                id
                startMonth
                startYear
                title
            }
            skillSearcher {
                id
                title
                category
                skillLevel
            }
            jobExperience {
                id
                startMonth
                startYear
                endMonth
                endYear
                yourRole
                summary {
                    raw
                }
                usedSkills {
                    id
                    title
                    category
                }
                client {
                    cvSummary {
                        raw
                    }
                    company
                    projectName
                }
                inProgress
            }
            additionalSections {
                id
                title
                textBlock {
                    raw
                }
            }
        }
    }
`;

interface Props {
    data: unknown;
    location: RouteLocation;
    pageContext: {
        locale: string;
    };
}

const CvPageTemplate = ({
    data: { cv, team },
    location,
    pageContext: { locale },
}: Props): JSX.Element => {
    const { theming: currentTheme } = team;

    const currentUrl = location.pathname;

    let backLink = null;

    const getIndex = (str: string, char: string, n: number) => {
        return str.split(char).slice(0, n).join(char).length;
    };

    if (currentUrl) {
        backLink = currentUrl.slice(0, getIndex(currentUrl, "/", 3));
    }

    return (
        <Layout
            lang={locale}
            teamLanguage={team.languages ?? "all"}
            title={`${cv.person.firstName} ${cv.person.name}`}
            theme={currentTheme}
            location={location}
        >
            <main>
                <div className="container-lg">
                    {backLink && team.teamProfiles.length > 1 && (
                        <a
                            href={backLink}
                            className={classNames(
                                style.backLink,
                                "d-inline-block mb-2",
                            )}
                        >
                            <FontAwesomeIcon
                                className={classNames(
                                    style.backLinkIcon,
                                    "mr-1",
                                )}
                                icon={faArrowLeft}
                                aria-hidden="true"
                                size="1x"
                            />
                            {t("back to team", locale)}
                        </a>
                    )}

                    {cv.person && <PersonCard person={cv.person} />}
                    <Row>
                        {cv.person.languages && (
                            <LanguagesList languages={cv.person.languages} />
                        )}
                        {cv.education && (
                            <EducationList educations={cv.education} />
                        )}
                    </Row>
                    {cv.skillSearcher && (
                        <Skills
                            data={cv.skillSearcher}
                            fill={currentTheme.secondaryColor}
                        />
                    )}
                    {cv.jobExperience && (
                        <Experience experiences={cv.jobExperience} />
                    )}
                    {cv.additionalSections && (
                        <AdditionalSectionList
                            additionalSections={cv.additionalSections}
                        />
                    )}
                </div>
                <Footer
                    theme={currentTheme}
                    contactPerson={team.contactPerson}
                />
            </main>
        </Layout>
    );
};
export default CvPageTemplate;
